<template>
  <div id="order-view-action-edit">
    <b-button
      id="order-view-action-cancel-edition"
      v-ripple.400
      :disabled="quoteTouched"
      variant="outline-danger"
      class="mb-75"
      block
      @click.prevent="quoteEditable = false"
    >
      <feather-icon :icon="btnIcon || 'XCircleIcon'" size="20" class="align-middle" />
      <span class="ml-25 align-middle"> {{ btnText || $t('action.cancel_edition') }} </span>
    </b-button>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
  name: 'OrderViewActionCancelEdition',

  props: {
    btnText: {
      type: String,
      default: '',
    },

    btnIcon: {
      type: String,
      default: '',
    },
  },

  computed: {
    ...mapFields('quote', ['quoteEditable', 'quoteTouched']),
  },
}
</script>
